import React from 'react';
import {createRoot} from 'react-dom/client';
import JshModule from "@core/scripts/helper/jsh-module";
import {BenefitTag} from "@bonprix/design-system";

do {

    const {moduleScope, config} = JshModule('teaser/benefit-tag');
    if (moduleScope === null) {
        continue;
    }

    const {benefit_name, benefit_displayType} = config;

    moduleScope.innerHTML = '';
    createRoot(moduleScope).render(
        <BenefitTag key={benefit_name}
                    variant={benefit_displayType.toLowerCase()} size="small">
            {benefit_name}
        </BenefitTag>
    );

} while (document.querySelector('[jsh-module="teaser/benefit-tag"]') !== null);
